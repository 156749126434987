<template>
  <b-modal v-model="modal" :title="title" size="lg" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="applyFilter()" autocomplete="off">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="type">Grupo</label>
                <select
                  class="custom-select"
                  name="type"
                  v-model="filter.type"
                >
                  <option value="">TODOS</option>
                  <option value="0">ACOMPANHANTE</option>
                  <option value="1">SUPERINTENDENTES MATRIZ E FUNCIONÁRIOS MATRIZ</option>
                  <option value="2">SUPERINTENDENTES E GERENTES COMERCIAIS, GERENTE DE ESCRITÓRIO, PARTNER, CONSULTORES E PROMOTORES</option>
                  <option value="3">DIRETORIA, CONSELHO, FAMILIA E PARCEIROS</option>
                  <option value="4">AEGON</option>
                  <option value="5">GALISTAS</option>
                  <option value="6">DESTAQUES</option>
                  <option value="7">DESTAQUES COM ACOMPANHANTE</option>
                  <option value="8">IMPRENSA</option>
                  <option value="9">CORRETORES CONVIDADOS COM CARTEIRA</option>
                  <option value="10">CORRETORES CONVIDADOS SEM CARTEIRA</option>
                  <option value="11">PARTICIPANTES GERAIS</option>
                  <option value="12">FUNCIONÁRIOS UNIDADES – PARTICIPAM ONLINE</option>
                </select>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="checkin_date">Check-In Data</label>
                <date-picker
                  id="checkin_date"
                  v-model="filter.checkin_date"
                  :first-day-of-week="1"
                  lang="pt-br"
                  format="DD/MM/YYYY"
                >
                </date-picker>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <label for="tags">Tags</label>
                <select
                  class="custom-select"
                  name="tag"
                  v-model="filter.tag"
                >
                  <option value="">TODOS</option>
                  <option v-for="(s,i) in tags" :key="i" :value="s.id">{{ s.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
      <b-button class="btn btn-dark" @click="clean()">Limpar</b-button>
      <button type="submit" class="btn btn-primary mr-1" @click="applyFilter()">Aplicar</button>
    </template>
  </b-modal>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import { states } from '@/pages/participants/statesData'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'participant-checkinfilter',
  components: {
    DatePicker
  },
  props: {},
  data () {
    return {
      title: 'Filtro - Participantes',
      selected: '',
      states: states,
      filter: {
      },
      modal: false
    }
  },
  mounted () {
    this.getTags()
  },
  methods: {
    ...mapActions('tag', {
      getAll: 'getAll'
    }),
    getTags (filter) {
      let params = {
        search: this.search || null,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    open () {
      this.modal = true
    },
    applyFilter () {
      // if (this.filter.tags.length > 0) {
      //   const pluck = (arr, key) => arr.map(i => i[key])
      //   this.filter.tagsIds = pluck(this.filter.tags, 'id')
      // }
      this.$emit('participantCheckinFiltered', this.filter)
      this.close()
    },
    close () {
      this.modal = false
    },
    clean () {
      this.filter = {}
      this.selected = ''
    }
  },
  computed: {
    ...mapState('tag', ['tags', 'pagination'])
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.form-check-label { padding-top: 2px !important }
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
