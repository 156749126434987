<template>
  <b-modal v-model="modal" :title="title" size="xl" :no-enforce-focus="true" :no-close-on-backdrop="true">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <form @submit.prevent="saveGroup()" autocomplete="off">
          <div class="row">
            <div class="col-sm-10">
              <div class="form-group">
                <label for="name">Nome</label>
                <input
                  id="name"
                  v-model="group.name"
                  name="name"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid': submitted && $v.group.name.$error
                  }"
                />
                <div
                  v-if="submitted && !$v.group.name.required"
                  class="invalid-feedback"
                >
                  O campo nome é obrigatório.
                </div>
              </div>
            </div>
            <div class="col-sm-2" style="margin-top:27px">
              <button type="submit" class="btn btn-primary btn-block">Salvar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-12">
        <div class="table-responsive">
          <table class="table table-centered table-nowrap table-hover">
            <thead>
              <tr>
                <th width="80%">Grupo</th>
                <th width="10%">Status</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="list in groups" :key="list.id">
                <td class="link" @click="editGroup(list.id)">
                  <span class="badge font-size-14 badge-soft-primary" style="min-width:100px">{{ list.name }}</span>
                </td>
                <td>
                  <b-dropdown
                    size="sm"
                    style="min-width:100px"
                    :variant="(parseInt(list.status)) === 1 ? 'success' : 'danger'"
                  >
                    <template v-slot:button-content >
                      {{ parseInt(list.status) === 1 ? 'Ativo' : 'Inativo' }}
                      <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <span>
                      <b-dropdown-item-button @click="toggleStatus(list.id)">Ativar</b-dropdown-item-button>
                      <b-dropdown-item-button @click="toggleStatus(list.id)">Inativar</b-dropdown-item-button>
                    </span>
                  </b-dropdown>
                </td>
                <td>
                  <button v-if="user.role === 'Admin'" type="button" @click="loadItem(list.id)" class="link btn btn-outline-dark btn-sm mr-1"><i class="bx bx-edit"></i></button>
                  <button v-if="user.role === 'Admin'" type="button" @click="deleteGroup(list)" class="link btn btn-outline-danger btn-sm"><i class="bx bx-trash"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="secondary" @click="close()">Fechar</b-button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Swal from 'sweetalert2'
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'

export default {
  components: {
    Loading
  },
  data () {
    return {
      title: 'Grupos',
      loading: true,
      fullPage: true,
      submitted: false,
      group: {},
      modal: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idGroup: null,
      perPage: 5,
      statusLabel: ['Inativo', 'Ativo']
    }
  },
  validations: {
    group: {
      name: { required }
    }
  },
  methods: {
    ...mapActions('group', {
      getGroup: 'show',
      postData: 'save',
      getAll: 'getAll',
      delete: 'deleteGroup',
      changeStatus: 'changeStatus'
    }),
    async toggleStatus (id) {
      try {
        await this.changeStatus(id)
        let message = 'Sucesso: Status atualizado com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.updateList()
      } catch (err) {
        console.log(err)
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    deleteGroup (group) {
      Swal.fire({
        title: 'Você tem certeza ?',
        html: `Será excluido o grupo: <b>${group.name}</b>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.delete(group.id)
            successMessage('Sucesso: Grupo excluído com sucesso.', 2500)
            this.updateList()
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getReport (filter) {
      let params = {
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    },
    loadItem (id) {
      if (id) {
        this.title = 'Editar Grupo'
        this.getGroup(id).then((data) => {
          this.group = this.data
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveGroup () {
      this.submitted = true
      this.$v.group.$touch()
      if (this.$v.group.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.group).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.group = {}
        this.$v.$reset()
        this.updateList()
        this.loading = false
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    close () {
      this.modal = false
      this.group = {}
      this.$v.$reset()
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('group', { data: 'group' }),
    ...mapState('group', ['groups', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
</style>
