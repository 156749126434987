4<template>
  <b-modal
    v-model="modal"
    :title="title"
    size="xl"
    :no-enforce-focus="true"
    :no-close-on-backdrop="true"
  >
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <div class="row">
      <div class="col-lg-12">
        <b-card-body>
          <form autocomplete="off">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="name">Nome completo</label>
                  <input
                    id="name"
                    v-model="guest.name"
                    name="name"
                    type="text"
                    class="form-control"
                    :disabled="guest.id && guest.status !== 99"
                    :class="{
                      'is-invalid': submitted && $v.guest.name.$error
                    }"
                  />
                  <div
                    v-if="submitted && !$v.guest.name.required"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório.
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="email">E-mail</label>
                  <input
                    id="email"
                    v-model="guest.email"
                    name="email"
                    type="text"
                    class="form-control"
                    :disabled="guest.id && guest.status !== 99"
                    :class="{
                      'is-invalid': submitted && $v.guest.email.$error
                    }"
                  />
                  <div
                    v-if="submitted && !$v.guest.email.required"
                    class="invalid-feedback"
                  >
                    O campo email é obrigatório.
                  </div>
                  <div
                    v-if="submitted && !$v.guest.email.email"
                    class="invalid-feedback"
                  >
                    Informe um e-mail válido.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="type">Grupo</label>
                  <select
                    class="custom-select"
                    name="type"
                    :disabled="guest.id && guest.status !== 99"
                    v-model="guest.group_id"
                  >
                    <option value=""></option>
                    <option v-for="(g,idx) in groups" :key="idx" :value="g.id">{{ g.name }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row" v-if="user.role === 'Admin'">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="tags">Tags</label>
                  <multiselect tag-placeholder="Selecione uma ou mais tags" placeholder="Selecione uma ou mais tags" v-model="guest.tags" label="name" track-by="id" :options="tags" :multiple="true"></multiselect>
                </div>
              </div>
            </div>
          </form>
        </b-card-body>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button v-if="user.role === 'Admin' && guest.id" variant="danger" @click="deleteGuest(guest)" class="float-left">Excluir Convidado</b-button>
      <b-button variant="secondary" @click="close()">Cancelar</b-button>
      <button type="submit" class="btn btn-primary mr-1" :disabled="guest.id && guest.status !== 99 && user.role !== 'Admin'" @click="saveGuest()">Salvar</button>
    </template>
  </b-modal>
</template>
<script>
// Import component
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Swal from 'sweetalert2'
import Multiselect from 'vue-multiselect'
import { mapActions, mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { cpf } from 'cpf-cnpj-validator'

export default {
  components: {
    Loading,
    Multiselect
  },
  props: {
    idGuest: {
      type: Number || String,
      default: null
    }
  },
  data () {
    return {
      title: 'Novo Convidado',
      listTags: [],
      loading: true,
      errorCPF: false,
      fullPage: true,
      submitted: false,
      guest: {},
      modal: false,
      modalForm: false
    }
  },
  validations: {
    guest: {
      name: { required },
      email: { required, email }
    }
  },
  mounted () {
    this.getTags()
  },
  methods: {
    ...mapActions('guest', {
      getGuest: 'getGuest',
      postData: 'save',
      destroyGuest: 'deleteGuest'
    }),
    ...mapActions('tag', {
      getAll: 'getAll'
    }),
    ...mapActions('group', {
      getAllGroups: 'getAll'
    }),
    validCPF () {
      const cpfValid = cpf.isValid(this.guest.cpf)
      if (!cpfValid) {
        this.errorCPF = true
        return
      }
      this.errorCPF = false
    },
    loadItem (id) {
      if (id) {
        this.title = 'Editar Convidado'
        this.getGuest(id).then((data) => {
          this.guest = this.data
          if (this.guest.tags) {
            this.guest.tags = this.guest.tags.data
          }
        }).then(() => {
          this.loading = false
        })
      }
    },
    open (id = null) {
      this.getGroups()
      this.modal = true
      this.loading = true
      if (id) {
        this.loadItem(id)
        return
      }
      this.loading = false
    },
    async saveGuest () {
      this.submitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        window.scroll(0, 0)
        return
      }
      this.loading = true
      await this.postData(this.guest).then(res => {
        let message = 'Sucesso: Dados armazenados com sucesso.'
        let delay = 2500
        successMessage(message, delay)
        this.close()
        this.$emit('created')
      }).catch((err) => {
        errorMessage('Alerta: ' + err.message)
        this.loading = false
      })
    },
    deleteGuest (guest) {
      Swal.fire({
        title: 'Você tem certeza ?',
        html: `Será excluído do sistema <b>${guest.name}</b>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#4a4848',
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'SIM, DESEJO EXCLUIR!'
      }).then(result => {
        if (result.value) {
          try {
            this.destroyGuest(guest.id).then((res) => {
              successMessage('Sucesso: Convidado excluído com sucesso.', 2500)
              this.close()
            }).catch((err) => {
              errorMessage('Alerta: ' + err.message)
            })
          } catch (err) {
            errorMessage('Alerta: ' + err.response.data.message)
          }
        }
      })
    },
    getTags (filter) {
      let params = {
        search: this.search || null,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
      }).catch(error => {
        console.log(error)
      })
    },
    getGroups () {
      let params = {
        search: this.search || null,
        status: 1,
        limit: 100,
        page: 1
      }
      this.params = params
      this.getAllGroups(this.params).then((res) => {
      }).catch(error => {
        console.log(error)
      })
    },
    close () {
      this.modal = false
      this.guest = {}
      this.$v.$reset()
      this.$emit('created')
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('event', ['setting']),
    ...mapState('guest', { data: 'guest' }),
    ...mapState('tag', ['tags', 'pagination']),
    ...mapState('group', ['groups', 'pagination'])
  },
  watch: {
    modal () {
      if (!this.modal) {
        this.close()
      }
    }
  }
}
</script>
<style scoped>
.vld-overlay.is-full-page {
    z-index: 1051 !important;
}
.modal-header {
  background: #000 !important;
  color: #fff;
}
.border-gray {
  border: 1px solid #e9e9e9;
}
</style>
