<template>
  <Layout>
    <checkin-statistic ref="child"></checkin-statistic>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-5">
                <div class="search-box mr-2 mb-2">
                  <div class="position-relative">
                    <input type="text" class="form-control" v-model="search" placeholder="Pesquise por Nome, E-mail ou CPF e pressione 'ENTER'" @keyup.enter="doSearch"/>
                    <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-7">
                <div class="float-sm-end d-flex">
                  <div class="text-sm-right me-2">
                      <button type="button" class="btn  btn-primary btn-rounded mb-2 mr-2" @click="showFilters()">
                        <i class="mdi mdi-filter mr-1"></i> Pesquisar com filtros
                      </button>
                    </div>
                  <div class="text-sm-right">
                    <button type="button" class="btn  btn-success btn-rounded mb-2 mr-2" @click="exportParticipants()">
                      <i class="mdi mdi-file-export-outline mr-1"></i> Exportar Dados
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-centered table-nowrap table-hover">
                <thead>
                  <tr>
                    <th width="40%">Nome</th>
                    <th width="20%">Grupo</th>
                    <th width="10%">Check-In</th>
                    <th width="10%">Tag</th>
                    <th width="10%">Ação</th>
                    <th width="10%">Operador</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in listcheckin" :key="list.id" class="link">
                    <td>
                      <h5 class="font-size-14 mb-1" style="white-space: nowrap;text-overflow: ellipsis;max-width: 275px;overflow: hidden;">{{ list.participant.data.name }}</h5>
                      <p class="text-muted mb-0">{{ list.participant.data.email }}</p>
                    </td>
                    <td>
                      <span class="badge badge-soft-secondary font-size-12" style="font-weight: 400; white-space: nowrap;text-overflow: ellipsis;max-width: 260px;overflow: hidden;">
                        {{ group[list.participant.data.type] }} {{ list.participant.data.event_companion ? ' EVENTO' : '' }} {{ list.participant.data.event_companion && list.participant.data.travel_companion ? ' E ' : '' }} {{ list.participant.data.travel_companion ? 'VIAGEM' : '' }}
                      </span>
                      <span class="badge font-size-12" :class="{'badge-soft-success': list.participant.data.isOnline === 1, 'badge-soft-primary': list.participant.data.isOnline === 0}" style="font-weight: 400; white-space: nowrap;text-overflow: ellipsis;max-width: 260px;overflow: hidden; display:inherit;">
                        {{ list.isOnline ? 'Online' : 'Presencial'}}
                      </span>
                    </td>
                    <td style="font-size: 12px !important">{{ list.event_dateBR }}</td>
                    <td style="font-size: 12px !important">
                      <span class="badge font-size-14 badge-soft-primary" style="min-width:100px" v-if="list.tag">{{ list.tag ? list.tag.data.name : '' }}</span>
                    </td>
                    <td>
                      <span class="badge font-size-12 p-2 badge-success" style="min-width: 120px">
                        {{ list.event_type }}
                      </span>
                      <br />
                    </td>
                    <td style="font-size: 12px !important">{{ list.operator ? list.operator.data.name : '' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @input="updateList"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-filter ref="modalFilter" @participantCheckinFiltered="getReport"></modal-filter>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import modalFilter from '@/components/modals/filters/ParticipantCheckInFilter'
import checkinStatistic from '@/components/widgets/checkin-statistic'
import appConfig from '@/app.config'
import { mapActions, mapState } from 'vuex'
import serviceReport from './services'

export default {
  components: {
    Layout,
    checkinStatistic,
    modalFilter
  },
  name: 'Participantes',
  page: {
    title: 'Participantes',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      timeout: 60000,
      search: '',
      statusClass: ['badge-dark', 'badge-primary', 'badge-warning', 'badge-success', 'badge-danger', 'badge-secondary'],
      refStatus: ['', 'Documentação', 'Contato telefônico', 'Emissão aéreo', 'Emissão transfer', 'Confirmação'],
      modalForm: false,
      modalFormC: false,
      modalDetail: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      idParticipant: null,
      perPage: 20,
      group: [
        'ACOMPANHANTE',
        'SUPERINTENDENTES MATRIZ E FUNCIONÁRIOS MATRIZ',
        'SUPERINTENDENTES E GERENTES COMERCIAIS, GERENTE DE ESCRITÓRIO, PARTNER, CONSULTORES E PROMOTORES',
        'DIRETORIA, CONSELHO, FAMILIA E PARCEIROS',
        'AEGON',
        'GALISTAS',
        'DESTAQUES',
        'DESTAQUES COM ACOMPANHANTE',
        'IMPRENSA',
        'CORRETORES CONVIDADOS COM CARTEIRA',
        'CORRETORES CONVIDADOS SEM CARTEIRA',
        'PARTICIPANTES GERAIS',
        'FUNCIONÁRIOS UNIDADES – PARTICIPAM ONLINE'
      ],
      submitted: false,
      title: 'Participantes',
      items: [{
        text: 'Participantes'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    // this.timeout = setInterval(() => {
    //   this.updateList()
    // }, 60000)
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  beforeDestroy () {
    this.cancelAutoUpdate()
  },
  computed: {
    ...mapState('settingcheck', ['listcheckin', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('settingcheck', {
      getListCheckin: 'getListCheckin'
    }),
    exportParticipants () {
      let loeader = this.$loading.show()
      serviceReport.exportChecklist(this.params).then((res) => {
        this.download(res)
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    cancelAutoUpdate () {
      clearInterval(this.timeout)
    },
    showFilters () {
      this.$refs.modalFilter.open()
    },
    showDetailsIndication (userId) {
      this.modalDetail = true
      this.$refs.modalDetails.open(userId)
    },
    getReport (filter) {
      let params = {
        type: filter.type || null,
        search: this.search || null,
        tag: filter.tag || null,
        limit: this.perPage,
        page: this.currentPage,
        checkin_date: filter.checkin_date ? this.$moment(filter.checkin_date).format('YYYY-MM-DD') : null
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getListCheckin(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
      // this.$refs.child.getSubscribers()
    }
  },
  watch: {
    // currentPage () { this.updateList() }
    // total () { this.updateList() },
  }
}
</script>
<style scoped>
.link { cursor: pointer; }
.inativado { background: #f7ebeb }
.swal2-popup {
  font-size: 11px !important;
}
.table-responsive { min-height: 300px;}
.float-sm-end { float: right }
</style>
